.rooms {
  $p: &;

  position: relative;

  // background-color: map-get($colors, 'bg');

  #rooms {
    position: absolute;
    top: -63px;
  }

  &__content {
    padding-top: 42px;
    padding-bottom: 45px;
  }

  &__title {
    margin-bottom: 45px;

    font-size: 25px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }

  &__item {
    position: relative;

    display: flex;
    flex-direction: column;

    // background-color: #faf9f7;
    background-color: #f4f3f1;

    &:nth-child(n + 4) {
      display: none;
    }
  }

  &__stock {
    position: absolute;
    z-index: 2;
    top: 9px;
    left: 4px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    aspect-ratio: 1;

    font-size: 12px;
    font-weight: 600;

    border-radius: 50%;
    color: #fff;
    background-color: map-get($colors, 'main');
  }

  &__slider {
    position: relative;
  }

  &__slide {
    display: flex;
    aspect-ratio: 1 / 0.63;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;

    transform: translateY(-50%);

    &::before {
      width: 33px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(4px);

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      left: 18px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid map-get($colors, 'main');
      border-left: 1px solid map-get($colors, 'main');
      transform: rotate(-45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    aspect-ratio: 1;

    transform: translateY(-50%);

    &::before {
      width: 33px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(4px);

      transition: transform 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      right: 18px;

      width: 7px;
      aspect-ratio: 1;

      border-top: 1px solid map-get($colors, 'main');
      border-right: 1px solid map-get($colors, 'main');
      transform: rotate(45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }

    &:active {
      &::before {
        transform: scale(0.9);
      }
    }
  }

  &__pagination {
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: 10px;

    display: flex;
    column-gap: 5px;

    transform: translateX(-50%);

    .swiper-pagination-bullet {
      width: 7px;
      aspect-ratio: 1;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.53);

      &-active {
        background-color: map-get($colors, 'main');
      }
    }
  }

  &__body {
    padding-top: 17px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 28px;
  }

  &__name {
    margin-bottom: 17px;

    font-size: 18px;
    font-weight: 600;
    line-height: 1.22;
    text-align: center;
    text-transform: uppercase;
  }

  &__properties {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    margin-bottom: 17px;

    span {
      display: flex;
      align-items: center;

      font-size: 12px;
      line-height: 1.5161;

      &::before {
        flex-shrink: 0;
        width: 6px;
        aspect-ratio: 1;
        margin-right: 8px;

        border-radius: 50%;
        background-color: map-get($colors, 'main');

        content: '';
      }
    }
  }

  &__attributes {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    margin-bottom: 21px;

    p {
      position: relative;

      width: calc(100% / 8 - 10px * 7 / 8);
      height: 20px;

      &:nth-child(1),
      &:nth-child(9) {
        span {
          left: -10px;
          transform: translateX(0);
        }
      }

      &:nth-child(8),
      &:nth-child(16) {
        span {
          left: auto;
          right: -10px;
          transform: translateX(0);
        }
      }

      &:hover {
        span {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    span {
      position: absolute;
      z-index: 1;
      left: 50%;
      bottom: calc(100% + 10px);

      padding: 10px;

      font-size: 13px;
      white-space: nowrap;

      border-radius: 3px;
      color: map-get($colors, 'main');
      background-color: #fff;
      box-shadow: 0px 15px 21px rgba(66, 66, 106, 0.1);
      transform: translateX(-50%);
      visibility: hidden;
      opacity: 0;

      transition: 0.2s;
    }
  }

  &__prices {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 12px;

    del {
      width: 100%;
      margin-bottom: 4px;

      font-size: 15px;
      font-weight: 600;
      line-height: 1.2;
      text-align: center;
      text-decoration: line-through;

      color: rgba(146, 133, 91, 0.66);
    }

    strong {
      margin-right: 8px;

      font-size: 23px;
      font-weight: 600;
      line-height: 1.22;

      color: map-get($colors, 'second');
    }

    span {
      font-size: 14px;
      line-height: 1.21;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__calculation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 238px;
    height: 52px;
    margin-bottom: 10px;

    font-size: 14px;
    text-transform: uppercase;

    color: #fff;
    background-color: map-get($colors, 'main');

    transition: background 0.2s;

    &:hover {
      background-color: map-get($colors, 'main-dark');
    }
  }

  &__booking {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 238px;
    height: 52px;

    font-size: 14px;
    text-transform: uppercase;

    border: 1px solid #000;

    transition: border 0.2s, color 0.2s, background 0.2s;

    &:hover {
      border-color: map-get($colors, 'main');
      color: #fff;
      background-color: map-get($colors, 'main');
    }
  }

  &__more {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 17px;
    margin-left: auto;
    margin-right: auto;

    font-size: 14px;
    font-weight: 600;
    line-height: 1.21;
    text-transform: uppercase;

    color: map-get($colors, 'second');

    transition: color 0.2s;

    &::after {
      width: 12px;
      aspect-ratio: 1;

      border-right: 1px solid map-get($colors, 'main');
      border-bottom: 1px solid map-get($colors, 'main');
      transform: rotate(45deg);

      transition: border 0.2s;

      content: '';
    }

    &:hover {
      color: map-get($colors, 'second-dark');

      &::after {
        border-color: map-get($colors, 'second-dark');
      }
    }
  }

  &__note {
    padding-top: 20px;

    font-size: 18px;
    line-height: 1.25;
    text-align: center;
  }

  &_opened {
    #{$p}__item {
      &:nth-child(n + 4) {
        display: flex;
      }
    }

    & #{$p}__more {
      display: none;
    }
  }
}

@media (min-width: 768px) {
  .rooms {
    #rooms {
      top: -106px;
    }

    &__content {
      padding-top: 66px;
      padding-bottom: 66px;
    }

    &__title {
      margin-bottom: 30px;

      font-size: 35px;
    }

    &__stock {
      top: 16px;
      left: 20px;

      width: 72px;

      font-size: 25px;
    }

    &__slide {
      aspect-ratio: 1 / 0.5;
    }

    &__prev {
      left: 20px;

      &::before {
        width: 39px;
      }

      &::after {
        width: 8px;
      }
    }

    &__next {
      right: 20px;

      &::before {
        width: 39px;
      }

      &::after {
        width: 8px;
      }
    }

    &__pagination {
      bottom: 28px;

      column-gap: 10px;

      .swiper-pagination-bullet {
        width: 12px;
      }
    }

    &__body {
      padding-top: 32px;
      padding-left: 34px;
      padding-right: 34px;
    }

    &__name {
      margin-bottom: 30px;

      font-size: 25px;
      text-align: left;
    }

    &__properties {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 30px;

      span {
        align-items: flex-start;
        width: 190px;

        font-size: 14px;

        &::before {
          margin-top: 7px;
          margin-right: 11px;
        }
      }
    }

    &__attributes {
      max-width: 300px;
      margin-bottom: 30px;
    }

    &__prices {
      flex-wrap: nowrap;
      justify-content: flex-start;
      margin-bottom: 24px;

      del {
        width: auto;
        margin-right: 15px;
        margin-bottom: 0;

        font-size: 18px;
      }

      strong {
        margin-right: 6px;

        font-size: 25px;
      }
    }

    &__buttons {
      flex-direction: row;
    }

    &__calculation {
      width: 203px;
      margin-right: 20px;
      margin-bottom: 0;
    }

    &__booking {
      width: 179px;
    }

    &__more {
      margin-top: 30px;
    }
  }
}

@media (min-width: 1200px) {
  .rooms {
    $p: &;

    &__content {
      padding-top: 94px;
      padding-bottom: 103px;
    }

    &__title {
      margin-bottom: 40px;
    }

    &__list {
      row-gap: 53px;
    }

    &__item {
      display: flex;
      flex-direction: row;

      &:nth-child(even) {
        #{$p}__stock {
          left: calc(50% + 20px);
        }

        #{$p}__slider {
          order: 1;
        }

        #{$p}__body {
          padding-left: 61px;
        }
      }
    }

    &__stock {
      top: 20px;
    }

    &__slider {
      width: 50%;

      .swiper {
        height: 100%;
        max-height: 471px;
      }
    }

    &__slide {
      height: 100%;
      aspect-ratio: auto;
    }

    &__body {
      width: 50%;
      padding-top: 48px;
      padding-left: 50px;
      padding-bottom: 50px;
    }

    &__properties {
      flex-wrap: wrap;
      justify-content: flex-start;
      row-gap: 16px;
      column-gap: 40px;
      margin-bottom: 39px;
    }

    &__attributes {
      margin-bottom: 37px;
    }

    &__prices {
      del {
        margin-right: 12px;
      }
    }

    &__more {
      margin-top: 48px;
    }
  }
}
